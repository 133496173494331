(function($){
    'use strict';
    
    var $carouselSlider = $('.js-highlights');

    if($carouselSlider.length){

        $carouselSlider.each(function() {
            var $cur = $(this),
                $carouselWrapper = $cur.find('.highlights__wrapper'),
                $prevArrow = $cur.find('.highlights__prev'),
                $nextArrow = $cur.find('.highlights__next');

                $carouselWrapper.on('init', function(){
                    $cur.find('.js-banner-pause').click(function(){
                        playPauseCarousel($carouselWrapper, $(this));
                        return false;
                    });
                });

                $cur.find('.highlights__wrapper').slick({
                    autoplay: true,
                    autoplaySpeed: 6000,
                    arrows: true,
                    prevArrow: $prevArrow,
                    nextArrow: $nextArrow,
                    dots: false,
                    infinite: true,
                    mobileFirst: true,
                    speed: 600,
                    slidesToShow: 1,
  					slidesToScroll: 1,
  					variableWidth: false,
  					responsive: [
					    {
					      breakpoint: 1199,
					      settings: {
					        slidesToShow: 6,
					        slidesToScroll: 1,
					      	variableWidth: true
					      }
					    },
					    {
					      breakpoint: 599,
					      settings: {
					        slidesToShow: 2,
					        slidesToScroll: 1,
					      	variableWidth: true
					      }
					    }
					]
                });

        });

        function playPauseCarousel($carouselSlider, $sliderToggle) {
            if ($sliderToggle.hasClass('active') ) {
                $carouselSlider.slick('slickPlay');
                $sliderToggle.removeClass('active');
            } else {
                $carouselSlider.slick('slickPause');
                $sliderToggle.addClass('active');
            }
        }
    }

}(jQuery));
